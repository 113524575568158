import './Inicio.css';

function Proyectos() {
    return (
        <section id="home">
            <div className="container">
                <h2>Proyectos <img className="verificado" src="/verificado.png" alt="Verificado" /></h2>
                <h2>Proyectos <img className="verificado" src="/verificado.png" alt="Verificado" /></h2>
            </div>
        </section>
    );
}

export default Proyectos;
